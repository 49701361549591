.custom-span {
  position: relative;
  display: inline-block;
}
.custom-span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f9ef99;
  border-radius: 20px;
  z-index: -1;
}
