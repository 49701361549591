.chat-room-and-info-wrapper {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 100%;
}

.chat-room-wrapper {
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-x: auto;
}

.chat-room-header {
  height: 64px;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  padding: 0px 10px;
  box-shadow: rgb(221, 221, 221) 0px 7px 9px -7px;
  column-gap: 10px;
  justify-content: space-between;
  width: inherit;
  .avatar-and-details-wrapper{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    width: calc(100% - 60px);


    .room-name-and-description-container {
      display: flex;
      flex-direction: column;
      width: inherit;

      .room-name{
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        font-family: "Segoe UI", Arial, Helvetica, Poppins;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width:100%;
      }
  
      .room-description{
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        font-family: "Segoe UI", Arial, "Helvetica Neue", Poppins;
        margin-top: 3px;
      }
    }
  }

  .avatar-and-details-wrapper.show-back-icon {
    width: calc(100% - 80px);
  }

  .chat-room-header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
  }
  
  .chat-room-header-right svg {
    cursor: pointer;
  }
  
  .chat-header-icon{
    cursor: pointer;
    flex-shrink: 0;
  }
}


$chat-room-bottom-height: calc(100% - 64px);

.chat-room-bottom-wrapper {
  height: $chat-room-bottom-height;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.disabled-input-toolbar {
  width: 100%;
  padding: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #ffffff;
  box-shadow: 0 0 15px 0 #dddddd;
}

.header-button{
  height:40px;
  width:40px;
  align-self: center;
  margin-top: -3px;
  flex-shrink: 0;
}

.chat-room-info-header{
  padding:16px;
  height:68px;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px whitesmoke;
}