.chat-list-item-container {
  max-height: 70px;
  width: inherit;
  border-radius: 10px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 0px 3px;

 

  .item-left-wrapper{
    flex-shrink: 0;
    flex-grow: 0;
    padding:10px 0px;
    width: 36px;
  }

  .item-right-wrapper {
    flex-grow: 1;
    height: 100%;
    width: calc(100% - 6px - 36px);
  }

  .item-content-wrapper{
    width: 100%;
    padding:10px 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .texts-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
  }

  .item-text {
    font-family: Helvetica;
  }

  .item-text.room-name{
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color:#1E1E1E;
  }

  .item-text.message-time{
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
  }

  .item-text.message-text{
    width: 90%;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color:#5C5B57CC;
    margin-top: 4px;
  }
  
  .item-text.unread-message-text{
    color: #1E1E1E;
  }

  .item-text.room-name, .item-text.message-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .unread-message-time{
    color:#3083EFCC
  }
}

.chat-list-item-container:hover {
  background: #e6ebff;
  cursor: pointer;
}
