.PRTRleftmaindiv1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 35%;
  border-right: 1px #dedede solid;
  padding: 10px;
}
@media (max-width: 600px) {
  .PRTRleftmaindiv1 {
    width: 100%;
  }
}
.refund-right-div2 {
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media (min-width: 601px) and (max-width: 900px) {
  .PRTRleftmaindiv1 {
    width: 50%;
  }
}
.payOut-main-box {
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 600px) {
  .payOut-main-box {
    padding: 10px;
  }
}
.payOut-grid-height {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  height: 83vh;
}
.payOut-child-div1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px;
}
@media (max-width: 600px) {
  .payOut-child-div1 {
    padding: 0px;
    flex-direction: column;
    border-bottom: 0px;
  }
}
.payOut-child-div2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  column-gap: 10px;
}
@media (max-width: 600px) {
  .payOut-child-div2 {
    width: 100%;
    row-gap: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
  }
}
.payOut-funds-div {
  width: 300px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
}
@media (max-width: 600px) {
  .payOut-funds-div {
    width: 48%;
    border-radius: 4px;
    padding: 10px;
  }
}
.payOut-search-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}
@media (max-width: 600px) {
  .payOut-search-div {
    justify-content: space-between;
  }
}
.payOut-map-wrap {
  width: 100%;
  margin: 15px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 60vh;
  overflow: scroll;
}
@media (max-width: 600px) {
  .payOut-map-wrap {
    margin: 10px 0px 0px 0px;
  }
}
.payOut-map-div {
  width: 350px;
  border: 1px solid rgb(222, 222, 222);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 600px) {
  .payOut-map-div {
    width: 100%;
  }
}
.payOut-pop-submit {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.payOut-charges-text-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-top: 0px;
  padding-top: 0px;
}
.payOut-pop-charges-div {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;
  border-radius: 4px;
  background: rgb(245, 248, 253);
  border: 1px solid rgb(7, 141, 218);
  margin: 10px 0px;
}
.payOut-note-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.payOut-helper-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
