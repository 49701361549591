@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Montserrat:wght@500;600&family=Mulish&family=Roboto:ital,wght@0,100;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.cursor_pointer{
  cursor: pointer;
}

.toolbar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding:8px;
  background: #FFFFFF;
  border-top: 0.1px solid #d4d4d4;
  box-shadow: 1px 1px 4px 0px #1E1E1E40;
  row-gap: 8px;
  flex-shrink: 0;
}

.toolbar_files_container{
  flex-shrink: 0;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  overflow-x: scroll;
}

.toolbar-emoji-button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  margin-bottom:1px;
}

.toolbar_footer{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #FFFFFF;
  column-gap: 8px;
}

.toolbar_footer_left{
  background: #F1F1F1;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  column-gap: 8px;
  border-radius: 4px;
  padding:8px;
}

.toolbar_footer_input{
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
  font-family: 'Poppins';
}


.render_text_input_sub_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.message_input_styles {
  width: 100%;
  height: 38px;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 23px 16px;
  outline-style: none;
  border: none;
  font-family: "Mulish", sans-serif !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.toolbar_footer_icon {
  height: 1px;
  width: 16px;
  cursor: pointer;
}

.send_button_styles_disabled {
  width: 42px;
  height: 38px;
  background: #dddddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.toolbar_footer_rounded_button {
  padding: 6px;
  background: #FFFFFF;
  border-radius: 100px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat_room_attachment_icon {
  width: 32px;
  height: 32px;
  gap: 0px;
  opacity: 0px;
  background-color: #ffffff;
}

.chat_image_container {
  height: 65px;
  width: 65px;
  border-radius: 4px;
}

.image_input_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.file_wrapper {
  padding: 0;
  margin: 0;
  position: relative;
  border-radius: 7px;
  border: dashed 1px #dddddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:150px;
  width: 150px;
  overflow: hidden;
  flex-shrink: 0;
}

.file_preview_container{
  flex-grow: 1;
  max-height: 150px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
}

.file_texts_wrapper{
  height:34px;
  background: rgba(0,0,0,0.5);
  width:100%;
  display:flex;
  flex-direction:column;
  padding:3px;
  row-gap: 3px;
}

.close_icon {
  position: absolute !important;
  color: red !important;
  top: 0px !important;
  right: 0px !important;
  cursor: pointer;
}
svg.document_icon_styles {
  font-size: 40px;
}

#MainEmoji {
  .EmojiPickerReact.epr-main {
    left:100px;
    height: auto;
    width: 100%;
  }

  aside.EmojiPickerReact.epr-main {
    position: relative !important;
    right: 0px;
    bottom: 10px;
    background-color: #e6ebff;
  }

  .Flex.epr-preview.FlexRow {
    display: none !important;
  }

  .epr-category-nav {
    padding: 1px !important;
    padding-bottom: 10px !important;
    padding-top: 0px !important;
  }

  .epr-emoji-category-label {
    font-size: 14px !important;
  }
}

.sound-wave {
  height: 30px; /* Set the desired height */
  width: 100%;
}
.audio-cont {
  width: 100%;
}


.toolbar_align_items_end_container{
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 6px;
}