.preview_wrapper {
  height:300px;
  width: 320px;
  @media (min-width:768px){
        height: 300px;
        width: 480px;
  }
}

.no_preview_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no_preview_wrapper.full_dimensions {
  height: 100%;
  width: 100%;
}


.no_preview_wrapper.full_dimensions.audio {
  width: 90%;
  padding:12px
}