.chat-room-info-container {
  display: flex;
  flex-direction: column;
  height: inherit;
  width: 100%;
  row-gap: 10px;
  overflow-y: auto;

  @media (min-width:600px) {
    padding: 16px;
  }

  .chat-room-info-md-header{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .chat-room-info-body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    .chat-room-info-profile {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 10px;
      @media(min-width: 600px){
          flex-direction: column;
          justify-content: center;
          width: 100%;
          row-gap: 10px;
      }

      .edit-icon-and-texts-wrapper{
        display: flex;
        width: calc(100% - 100px);
        column-gap: 10px;
        align-items: center;
      }

      .chat-room-info-texts-wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
      }  

      .overflow-text{
        font-family: "Segoe UI", "Helvetica Neue", Poppins;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        @media (min-width:600px){
          text-align: center;
        }
      }
  
      .overflow-text.room-name{
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;      
      }

      .overflow-text.room-description{
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin-top: 3px;
      }

      .group-edit-icon{
        height: 22px;
        width: 22px;
        @media (min-width:600px){
          height: 36px;
        width: 36px;
        }
      }
    }
  }
  
  .members-list-wrapper{
      border: solid 1px rgba(221, 221, 221, 0.7);
      border-radius: 4px;
      overflow-y: auto;
  }
  
  .chat-room-info-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: "center";
  }
  
  .chat-room-info-action-btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
    text-transform: none;
    color: red;
  }
  
  .chat-room-info-access-card-wrapper {
      display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px rgba(221, 221, 221, 0.7);
    border-radius: 4px;
    padding: 10px;
    
  }  
}

