.header-text-container {
    height: 68px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    padding: 16px ;
    @media(min-width:600px){
      height: 54px;
    }
  }

.header-text-container.header-border {
    border-bottom: solid 1px #ededed;
    @media(min-width:600px){
        padding-top: 0px;
    }
}