.messages-container-wrapper {
  flex: 1 1 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  overflow-y: auto;
}

.messages-container {
  flex: 1 1 100%;
  padding: 0px 1px 4px 1px;
  overflow-y: auto;
  ul{
    row-gap: 8px;
  }
}

.message-options {
  height: 20px;
  width: 18px;
  position: relative;
  align-self: flex-start;

  

}

.message-options.right {
  right: 0px;
}
.message-options.left {
  left: 0px;
}

.message-option-item{
  display: flex;
  flex-direction: column;
  padding: 0;
}

.message-option-label{
  font-family: 'Helvetica Neue',Poppins;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #5C5B57;
}

.selected-msg {
  height: 30px;
  width: 30px;
  align-self: center;
}

.column-reverse-div {
  display: flex;
  flex-direction: column-reverse;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.div-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

.highlighted-search-msgs-count-container{
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center
}

.searchbar {
  padding: 8px;
}

.scroll-btn {
  opacity: 0.8;
  z-index: 89;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: white;
  border-radius: 20px;
  border: solid 1px #e6ebff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scroll-btn:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.system-message-container{
  margin: 5px 0px 10px 0px;
  width: 100%;

  .system-message-text{
    background-color: transparent;
    color: black;
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    padding:0px 8px;
    width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.day-wrapper{
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 10px;

 .day-text {
    color: #000;
    font-size: 10px;
    font-weight: 400;
  }
}

.bubble{
  background-color: transparent;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  max-width: 100%;
}

.bubble.right{
  justify-content: flex-end;
}

.bubble.left{
  justify-content: flex-start;
}


.bubble-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 4px;
  width: inherit;

  .bubble-wrapper {
    display: flex;
    flex-direction: row;
    width: inherit;
  }

  .bubble-wrapper.right{
    justify-content: flex-end;
    align-items: flex-end;
  }

  .bubble-wrapper.left{
    justify-content: flex-start;
    align-items: flex-start;
  }


  .bubble-content {
    width: 300px;
    @media (min-width: 768px) {
      width: calc(100% - 20px);
    }
    @media (min-width: 992px) {
      width: calc(100% - 50px);
    }
  
    .bubble-username-time-wrapper{
      display: flex;
      flex-direction: row;
      align-items: baseline;
      column-gap: 5px;
    }
    
    .bubble-username-time-wrapper.right{
      justify-content: flex-end;
    }

    .bubble-username-time-wrapper.left{
      justify-content: flex-start;
    }

    .bubble-content-and-edit-icon-wrapper{
      display: flex;
      flex-direction: row;
      column-gap: 8px;
      width: auto;

      .message-edit-icon{
        align-self: center;
      }
    }

    .bubble-content-and-edit-icon-wrapper.left{
      justify-content: flex-start;
    }

    .bubble-content-and-edit-icon-wrapper.right{
      justify-content: flex-end;
    }

    .bubble-content-wrapper{
      width: inherit;
      max-width: 90%;
      display: flex;
      min-height: 20px;
      border-radius: 5px;
      padding: 8px;
    }

    .bubble-content-wrapper.left{
      background-color: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      border:solid 1px #e6ebff;
      justify-content: flex-end;
      border-top-left-radius:0;
    }

    .bubble-content-wrapper.right{
      background-color: #cce1fc;
      justify-content: flex-start;
      border-top-right-radius:0;
    }
  }
}
