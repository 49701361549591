@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    width: 100vw;
}

@font-face {
    font-family: 'HelveticaNeueBold';
    src: url('../fonts/HelveticaNeueBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueLight';
    src: url('../fonts/HelveticaNeueLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@mixin label-style {
    height: 18px;
    width: 140px;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
}

// #LEARN_NEW_PROFESSION_INS {
//     height: 545px;
//     display: flex;
//     margin-top: 80px;
//     position: relative;
//     left: 0;
//     right: 0;

//     margin-bottom: 40px;

//     .learn_new_profession_frame {
//         // border: solid;
//         // margin-left: 100px;
//         // // margin-top: 175px;
//         // margin-right: 100px;
//         // width: 724px;
//         // height: 447px;
//         // display: flex;
//         // flex-direction: column;
//         // align-items: flex-start;
//         // justify-content: flex-start;

//         margin-left: 100px;
//         margin-right: 100px;
//         width: 724px;
//         display: flex;
//         flex-direction: column;
//         align-items: flex-start;
//         justify-content: center;
//         height: 100%;

//         .learn_new_profession_text {
//             width: 724px;
//             height: 270px;
//             display: flex;
//             flex-direction: column;
//             align-items: flex-start;
//             justify-content: flex-start;
//             position: relative;

//             .learn_new_profession_label {

//                 width: 724px;
//                 height: 145px;
//                 font-size: 54px;
//                 font-weight: 700;
//                 font-family: "HelveticaNeueBold", sans-serif;
//                 line-height: 66px;
//                 text-align: left;

//                 .learn_new_profession_span {
//                     color: #3083EF;
//                 }
//             }

//             .learn_new_profession_label2 {
//                 width: 620px;
//                 height: 90px;
//                 font-family: "HelveticaNeueLight", sans-serif;
//                 font-size: 23px;
//                 font-weight: 600;
//                 line-height: 35px;
//                 text-align: left;
//                 color: #5C5B57;
//                 margin-top: 13px;
//             }

//             // .text_underline_container {
//             //     position: absolute;
//             //     top: 70px;
//             //     left: 40px;
//             // }
//             // .text_underline_container {
//             //     position: absolute;
//             //     top: 60px;
//             //     left: 40px;
//             // }
// .text_underline_container {
//     position: absolute;
//     top: 100px;
//     left: 40px;
//     z-index: -1;
// }
//         }

//         .search_all_courses_container {
//             // width: 700px;
//             // height: 68px;
//             // width: 565px;
//             // height: 60px;
//             width: 615px;
//             height: 68px;
//             padding: 18px 19px 18px 14px;
//             gap: 0px;
//             border-radius: 10px;
//             
//             border: solid 2px #E4E4E4;
//             margin-top: 10px;
//             display: flex;
//             align-items: center;

//             .search_all_courses_input {
//                 flex: 1;
//             }
//         }

//         .learn_new_profession_button_container {
//             margin-top: 20px;
//             width: 516px;
//             height: 60px;
//             gap: 20px;
//             
//             display: flex;

//             .learn_new_profession_button {
//                 // width: 248px;
//                 // height: 60px;
//                 width: 240px;
//                 height: 55px;
//                 padding: 10px 30px 10px 30px;
//                 gap: 10px;
//                 border-radius: 10px;
//                 
//                 background: #3083EF;
//                 text-transform: capitalize;
//                 font-family: "Lato", sans-serif;
//                 font-size: 18px;
//                 font-weight: 600;
//                 line-height: 20px;
//                 text-align: center;

//             }

//             .see_how_it_works_container {
//                 position: relative;
//                 display: flex;
//                 align-items: center;

//                 .see_how_it_works_label {
//                     font-family: "Lato", sans-serif;
//                     font-size: 18px;
//                     font-weight: 600;
//                     line-height: 20px;
//                     text-align: center;
//                     margin-left: 10px;
//                     width: 136px;
//                     height: 20px;
//                     gap: 0px;
//                     

//                 }

//                 .text_underline {
//                     position: absolute;
//                     top: 43px;
//                     right: 10px;
//                 }
//             }

//         }
//     }

//     @media screen and (max-width: 1536px) {
//         .learn_new_profession_frame {
//             margin-right: 0px;
//         }

//     }

//     .learn_professions_right_image {
//         flex: 1;
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         justify-content: flex-end;
//         margin-right: 100px;

//         img {
//             height: 515px;
//             margin-top: 45px;
//         }
//     }
// }
#LEARN_NEW_PROFESSION_INS {
    // height: calc(300px + (545 - 300) * ((100vw - 320px) / (1550 - 320)));
    display: flex;
    margin-top: calc(40px + (80 - 40) * ((100vw - 320px) / (1550 - 320)));
    position: relative;
    width: 100%;

    height: max-content;
    margin-bottom: 40px;
    flex-direction: row;
    align-items: center;


    .learn_new_profession_frame {
        margin-left: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));
        // margin-right: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));
        width: calc(300px + (650 - 300) * ((100vw - 320px) / (1550 - 320)));
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;


        .learn_new_profession_text {

            width: calc(300px + (724 - 300) * ((100vw - 320px) / (1550 - 320)));
            // height: calc(150px + (270 - 150) * ((100vw - 320px) / (1550 - 320)));
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            position: relative;

            height: max-content;

            .text_underline_container {
                position: absolute;
                top: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
                left: calc(20px + (40 - 20) * ((100vw - 320px) / (1550 - 320)));
                z-index: -1;
            }

            .learn_new_profession_label {
                width: calc(300px + (724 - 300) * ((100vw - 320px) / (1550 - 320)));
                // height: calc(100px + (215 - 100) * ((100vw - 320px) / (1550 - 320)));
                font-size: calc(30px + (54 - 30) * ((100vw - 320px) / (1550 - 320)));
                font-weight: 700;
                font-family: "HelveticaNeueBold", sans-serif;
                line-height: calc(40px + (66 - 40) * ((100vw - 320px) / (1550 - 320)));
                text-align: left;
                height: max-content;

                .learn_new_profession_span {
                    color: #3083EF;
                }
            }

            .learn_new_profession_label2 {
                width: calc(250px + (724 - 300) * ((100vw - 320px) / (1550 - 320)));
                // height: calc(20px + (28 - 20) * ((100vw - 320px) / (1550 - 320)));
                height: max-content;
                font-family: "HelveticaNeueLight", sans-serif;
                font-size: calc(16px + (23 - 16) * ((100vw - 320px) / (1550 - 320)));
                font-weight: 600;
                // line-height: calc(12px + (30 - 12) * ((100vw - 320px) / (1550 - 320)));
                text-align: left;
                color: #5C5B57;
                margin-top: calc(8px + (13 - 8) * ((100vw - 320px) / (1550 - 320)));
                // line-height: 28px;
            }

            .text_underline_container {
                position: absolute;
                top: calc(40px + (70 - 40) * ((100vw - 320px) / (1550 - 320)));
                left: calc(20px + (40 - 20) * ((100vw - 320px) / (1550 - 320)));
            }

        }

        .search_all_courses_container {
            width: calc(300px + (625 - 300) * ((100vw - 320px) / (1550 - 320)));
            height: calc(40px + (60 - 40) * ((100vw - 320px) / (1550 - 320)));
            padding: calc(10px + (18 - 10) * ((100vw - 320px) / (1550 - 320))) calc(10px + (19 - 10) * ((100vw - 320px) / (1550 - 320))) calc(10px + (18 - 10) * ((100vw - 320px) / (1550 - 320))) calc(8px + (14 - 8) * ((100vw - 320px) / (1550 - 320)));
            gap: 0px;
            border-radius: 10px;
            border: solid 2px #E4E4E4;
            margin-top: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
            display: flex;
            align-items: center;



            .search_all_courses_input {
                flex: 1;
            }
        }

        .learn_new_profession_button_container {
            margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1550 - 320)));
            width: calc(300px + (516 - 300) * ((100vw - 320px) / (1550 - 320)));
            height: calc(40px + (60 - 40) * ((100vw - 320px) / (1550 - 320)));
            gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1550 - 320)));
            display: flex;

            .learn_new_profession_button {
                width: calc(150px + (240 - 150) * ((100vw - 320px) / (1550 - 320)));
                height: calc(40px + (55 - 40) * ((100vw - 320px) / (1550 - 320)));
                padding: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320))) calc(15px + (30 - 15) * ((100vw - 320px) / (1550 - 320)));
                gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
                border-radius: 10px;
                background: #3083EF;
                text-transform: capitalize;
                font-family: "Lato", sans-serif;
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1550 - 320)));
                font-weight: 600;
                line-height: calc(16px + (20 - 16) * ((100vw - 320px) / (1550 - 320)));
                text-align: center;
            }


            .see_how_it_works_container {
                position: relative;
                display: flex;
                align-items: center;

                .see_how_it_works_label {
                    font-family: "Lato", sans-serif;
                    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1550 - 320)));
                    font-weight: 600;
                    line-height: calc(16px + (20 - 16) * ((100vw - 320px) / (1550 - 320)));
                    text-align: center;
                    margin-left: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
                    // width: calc(100px + (136 - 100) * ((100vw - 320px) / (1550 - 320)));
                    height: calc(16px + (20 - 16) * ((100vw - 320px) / (1550 - 320)));
                }


                .text_underline {
                    position: absolute;
                    // top: calc(30px + (45 - 30) * ((100vw - 320px) / (1550 - 320)));
                    right: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
                    bottom: -33px;
                }

            }

        }
    }

    .learn_professions_right_image {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-right: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));

        img {
            height: calc(300px + (515 - 300) * ((100vw - 320px) / (1550 - 320)));
            margin-top: calc(20px + (45 - 20) * ((100vw - 320px) / (1550 - 320)));
        }
    }

    .learn_professions_right_image_top {
        display: none;
    }

}

#WHAT_AAUTI_HELPS_YOU_IN {
    height: 836px;
    background-color: #F2F2F6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .what_aauti_helps_you_in_container {
        .what_aauti_helps_you_in_text {
            width: 736px;
            height: 126px;
            // margin-top: 68px;
            // border: solid;
            margin-right: 80px;

            .what_aauti_helps_you_in_label {
                font-family: "HelveticaNeueBold", sans-serif;
                font-size: 44px;
                font-weight: 700;
                line-height: 70px;
                text-align: left;

                .what_aauti_helps_you_in_span {
                    color: #3083EF;
                }

                .what_aauti_helps_you_in_label2 {
                    font-family: "HelveticaNeueLight", sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 36px;
                    text-align: left;

                }
            }
        }
    }
}

.institutions_box_featured_in {
    // height: 439px;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 40px;
    margin-bottom: 40px;

    .institutions_box_featured_in_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        position: relative;


        .institutions_box_featured_in_label {
            font-family: "HelveticaNeueBold", sans-serif;
            font-size: calc(18px + (44 - 18) * ((100vw - 320px) / (1550 - 320)));
            font-weight: 700;
            line-height: calc(56px + (56 - 20) * ((100vw - 320px) / (1550 - 320)));
            text-align: center;
        }


        .background_highlight {
            position: absolute;
            z-index: -1;
            top: calc(24px + (48 - 24) * ((100vw - 320px) / (1550 - 320)));
            margin-left: calc(95px + (150 - 95) * ((100vw - 320px) / (1550 - 320)));
        }

    }
}

.still_have_doubts {
    margin-left: 100px;
    margin-right: 100px;
    height: 540px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .still_have_doubts_container {
        width: 685px;

        .still_have_doubts_label {
            font-family: "HelveticaNeueLight", sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            text-align: left;
            color: #3083EF;


        }

        .still_have_doubts_label2 {
            font-family: "HelveticaNeueLight", sans-serif;
            font-size: 40px;
            font-weight: 700;
            line-height: 50px;
            text-align: left;
            margin-top: 7px;
        }

        .still_have_doubts_label3 {
            font-family: "HelveticaNeueLight", sans-serif;
            font-size: 20px;
            font-weight: 700;
            line-height: 20px;
            text-align: left;
            margin-top: 7px;

        }

    }

    .still_have_doubts_form_container {
        width: 520px;
        height: 406px;
        top: 67px;
        left: 863px;
        gap: 0px;
        border-radius: 16px;

        background-color: #F2F2F6;
        padding: 16px;

        .still_have_doubts_form_label {
            font-family: "HelveticaNeueLight", sans-serif;
            font-size: 26px;
            font-weight: 500;
            line-height: 26px;
            text-align: left;

        }

        .still_have_doubts_form_label2 {
            font-family: "HelveticaNeueLight", sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            text-align: left;
            margin-top: 10px;
        }

        .still_have_doubts_form_inputs {
            display: flex;
            flex-direction: column;
            align-items: center;

            input {
                width: 480px;
                height: 56px;
                border-radius: 10px;
                background: #FFFFFF;
                border: none;
                outline: none;
                margin-top: 10px;
                margin-bottom: 10px;
                padding-left: 10px;
                padding-right: 10px;
            }

            .still_have_doubts_form_button {
                background-color: #3083EF;
                width: 233px;
                height: 46px;
                // top: 316px;
                // left: 143.5px;
                padding: 16.5px 80px 16.5px 80px;
                // gap: 10px;
                border-radius: 6px;
                margin-top: 30px;
                text-transform: capitalize;
            }
        }
    }
}

.institutions_box_what_aauti_helps_you_in {
    // border: solid;
    width: 100%;
    height: max-content;
}


@media screen and (max-width: 899px) {
    #LEARN_NEW_PROFESSION_INS {
        flex-direction: column;
        align-items: center;
        height: max-content;
        padding-bottom: 40px;
        margin-top: 80px;

        .learn_new_profession_frame {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .learn_new_profession_text {
                height: max-content;

                .text_underline_container {
                    display: none;
                }

                .learn_new_profession_label {
                    height: max-content;

                    .learn_new_profession_svg {
                        display: none;
                    }
                }

                .learn_new_profession_label2 {
                    width: 100%;
                    height: max-content;
                    margin-bottom: 10px;
                }
            }
        }

        .learn_professions_right_image {
            display: none;
        }

        .learn_professions_right_image_top {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }


    #WHAT_AAUTI_HELPS_YOU_IN {
        width: 100%;
        height: max-content !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;

        .what_aauti_helps_you_in_image_five {
            display: none;
        }

        .what_aauti_helps_you_in_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 40px;

            .what_aauti_helps_you_in_text {
                text-align: center !important;
                margin: 0px;
                width: 100% !important;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 25px;
                height: max-content !important;
                margin-right: 0px !important;

                .what_aauti_helps_you_in_label {
                    text-align: center !important;
                }

                .what_aauti_helps_you_in_label2 {
                    margin-bottom: 14px;
                }
            }

            .what_aauti_helps_you_in_image {
                width: 100% !important;
                padding: 20px;
            }
        }
    }

    .institutions_box_featured_in {
        margin-left: 25px;
        margin-right: 25px;
        margin-top: 40px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;

        .institutions_box_featured_in_container {
            width: 100%;

            .background_highlight {
                display: none;
            }
        }

        .institutions_box_featured_in_image {
            width: 100%;
        }
    }

    .still_have_doubts {
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin: 25px;

        .still_have_doubts_container {
            width: 100%;
        }

        .still_have_doubts_form_container {
            width: 100%;
            height: max-content;
            border-radius: 16px;
            background-color: #F2F2F6;
            padding: 16px;
            margin: 20px;

            .still_have_doubts_form_inputs {
                input {
                    width: 100%;
                    height: 56px;
                    border-radius: 10px;
                    background: #FFFFFF;
                    border: none;
                    outline: none;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }
}

.what_aauti_helps_you_in_image_mobile {
    display: none;
}

.what_aauti_helps_you_in_image_mobile_cards_container {
    display: none;
}

// #WHAT_AAUTI_HELPS_YOU_IN_MOBILE {
//     display: none;
// }
#WHAT_AAUTI_HELPS_YOU_IN_MOBILE {
    width: 100%;
    height: max-content !important;
    display: none !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;

    .what_aauti_helps_you_in_image_five {
        display: none;
    }

    .what_aauti_helps_you_in_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 40px;

        .what_aauti_helps_you_in_text {
            text-align: center !important;
            margin: 0px;
            width: 100% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 25px;
            height: max-content !important;
            margin-right: 0px !important;

            .what_aauti_helps_you_in_label {
                text-align: center !important;
            }

            .what_aauti_helps_you_in_label2 {
                margin-bottom: 14px;
            }
        }

        .what_aauti_helps_you_in_image {
            width: 100% !important;
            padding: 20px;
        }
    }
}

@media screen and (max-width:500px) {
    #LEARN_NEW_PROFESSION_INS .learn_new_profession_frame .learn_new_profession_text .learn_new_profession_label {
        font-size: 28px;
    }

    // .future_learning_systems_span{
    //     background-color: #FFDE72;
    // }
    #LEARN_NEW_PROFESSION_INS .learn_new_profession_frame .learn_new_profession_text .text_underline_container {
        display: block !important;
        left: 0px;

        svg {
            width: 200px;
        }
    }

    #WHAT_AAUTI_HELPS_YOU_IN .what_aauti_helps_you_in_container .what_aauti_helps_you_in_text .what_aauti_helps_you_in_label {
        font-size: 28px !important;
        text-align: center !important;
    }

    .what_aauti_helps_you_in_image_mobile {
        display: block !important;
        margin-top: 15px;
    }

    .what_aauti_helps_you_in_image_mobile_cards_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 15px;
        overflow: auto;
    }

    .need_span {
        background-color: #F9EF99;
        border-radius: 15px;
    }

    #WHAT_AAUTI_HELPS_YOU_IN_MOBILE {
        display: flex !important;
    }

    #WHAT_AAUTI_HELPS_YOU_IN_MOBILE .what_aauti_helps_you_in_container .what_aauti_helps_you_in_text .what_aauti_helps_you_in_label {
        text-align: center !important;
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .institutions_box_what_aauti_helps_you_in {
        display: none;
    }

    #WHAT_AAUTI_HELPS_YOU_IN_MOBILE .what_aauti_helps_you_in_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 40px;
        background: #f2f2f6;
        padding-top: 15px;
    }

    .what_aauti_helps_you_in_span {
        color: #3083EF;
    }

    .still_have_doubts .still_have_doubts_container .still_have_doubts_label2 {
        font-weight: 700;
        line-height: 35px;
        text-align: center;
        margin-top: 7px;
        font-size: 24px;
    }

    .still_have_doubts .still_have_doubts_container .still_have_doubts_label {
        text-align: center;
    }

    .still_have_doubts .still_have_doubts_container .still_have_doubts_label3 {
        font-family: "HelveticaNeueLight", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        margin-top: 7px;
    }

    .still_have_doubts .still_have_doubts_form_container .still_have_doubts_form_label {
        text-align: center;
    }

    .still_have_doubts .still_have_doubts_form_container .still_have_doubts_form_label2 {
        text-align: center;
    }

    #FAQ_CONTAINER .faq_label_container .faq_label {
        font-size: 26px;
    }
}