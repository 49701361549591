@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  width: 100vw;
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: url("../fonts/HelveticaNeueBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueLight";
  src: url("../fonts/HelveticaNeueLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@mixin label-style {
  height: calc(14px + (18 - 14) * ((100vw - 320px) / (1550 - 320)));
  width: calc(120px + (140 - 120) * ((100vw - 320px) / (1550 - 320)));
  font-family: "Lato", sans-serif;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1550 - 320)));
  font-weight: 700;
  line-height: calc(16px + (18 - 16) * ((100vw - 320px) / (1550 - 320)));
  text-align: left;
}

.sidebar-icon {
  height: 30px;
  width: 30px;
}
.sidebar-link {
  text-decoration: none;
  color: #000;
  cursor: none;
}
.sidebar-link.active {
  color: blue;
}
li.side-list-item {
  display: flex !important;
  flex-direction: column;
  align-items: unset !important;
  cursor: pointer;
  padding-right: unset !important;
}
li.side-list-item:active {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent !important;
}
// li.MuiListItem-root:active {
//   background-color: transparent;
// }

// @media (max-width: 1225px) {
//     #HOME_PAGE_HEADER {
//         display: flex;
//         justify-content: space-between;
//         width: 100%;

//         .aauti_buttons {
//             display: none !important;
//         }

//         .aauti_search_frame {
//             display: none !important;
//         }

//         .aauti_menu_button {
//             display: block !important;
//             margin-right: 50px;
//         }
//     }
// }

// #HOME_PAGE_HEADER {
//     height: calc(50px + (80 - 50) * ((100vw - 320px) / (1550 - 320)));
//     background-color: #ffffff;
//     padding-left: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));
//     box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.12);
//     display: flex;
//     align-items: center;
//     position: fixed;
//     z-index: 201;
//     left: 0;
//     right: 0;
//     top: 0;

//     .aauti_logo {
//         height: 40px;
//         width: auto;
//         cursor: pointer;
//     }

//     .aauti_buttons {
//         width: calc(320px + (512 - 320) * ((100vw - 320px) / (1550 - 320)));
//         height: calc(30px + (50 - 30) * ((100vw - 320px) / (1550 - 320)));
//         margin-left: calc(100px + (250 - 100) * ((100vw - 320px) / (1550 - 320)));
//         padding: 10px;
//         display: flex;
//         flex-direction: row;
//         align-items: center;

//         .aauti_all_courses {
//             width: calc(80px + (116 - 80) * ((100vw - 320px) / (1550 - 320)));
//             height: calc(20px + (30 - 20) * ((100vw - 320px) / (1550 - 320)));
//             margin-right: calc(10px + (15 - 10) * ((100vw - 320px) / (1550 - 320)));
//             gap: 0px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             padding: 6px;

//             .aauti_button_base {
//                 width: calc(80px + (106 - 80) * ((100vw - 320px) / (1550 - 320)));
//                 height: calc(15px + (20 - 15) * ((100vw - 320px) / (1550 - 320)));
//                 gap: calc(4px + (6 - 4) * ((100vw - 320px) / (1550 - 320)));
//                 border-radius: 8px;
//                 display: flex;
//                 flex-direction: row;
//                 align-items: center;
//                 justify-content: space-between;
//                 cursor: pointer;

//                 .aauti_label {
//                     @include label-style();
//                 }

//                 svg.aauti_label_icon {
//                     margin-top: 5px;
//                 }
//             }
//         }

//         .teach_with_aauti {
//             width: calc(100px + (140 - 100) * ((100vw - 320px) / (1550 - 320)));
//             height: calc(20px + (30 - 20) * ((100vw - 320px) / (1550 - 320)));
//             padding: calc(4px + (6 - 4) * ((100vw - 320px) / (1550 - 320))) calc(3px + (5 - 3) * ((100vw - 320px) / (1550 - 320))) calc(4px + (6 - 4) * ((100vw - 320px) / (1550 - 320))) calc(3px + (5 - 3) * ((100vw - 320px) / (1550 - 320)));
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             cursor: pointer;
//             ;

//             .aauti_label {
//                 @include label-style();
//             }
//         }

//         .institutions {
//             width: calc(70px + (91 - 70) * ((100vw - 320px) / (1550 - 320)));
//             height: calc(20px + (30 - 20) * ((100vw - 320px) / (1550 - 320)));
//             padding: calc(4px + (6 - 4) * ((100vw - 320px) / (1550 - 320))) calc(3px + (5 - 3) * ((100vw - 320px) / (1550 - 320))) calc(4px + (6 - 4) * ((100vw - 320px) / (1550 - 320))) calc(3px + (5 - 3) * ((100vw - 320px) / (1550 - 320)));
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             cursor: pointer;

//             .aauti_label {
//                 @include label-style();
//                 margin-left: 10px;
//                 ;
//             }
//         }

//     }

//     .aauti_menu_button {
//         display: none;
//     }

//     @media screen and (max-width: 1536px) {
//         .aauti_buttons {
//             margin-left: 70px;
//         }
//     }

//     .aauti_search_frame {
//         width: calc(400px + (579 - 400) * ((100vw - 320px) / (1550 - 320)));
//         height: calc(35px + (45 - 35) * ((100vw - 320px) / (1550 - 320)));
//         gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1550 - 320)));
//         display: flex;
//         flex-direction: row;
//         flex: 1;
//         margin-right: calc(50px + (100 - 50) * ((100vw - 320px) / (1550 - 320)));
//         justify-content: flex-end;

//         .aauti_search_input {
//             border: solid 1px #E4E4E4;
//             width: calc(250px + (307 - 250) * ((100vw - 320px) / (1550 - 320)));
//             height: calc(35px + (42 - 35) * ((100vw - 320px) / (1550 - 320)));
//             border-radius: calc(8px + (10 - 8) * ((100vw - 320px) / (1550 - 320)));
//             display: flex;
//             align-items: center;

//             .aauti_search_input_text {
//                 padding-left: calc(8px + (10 - 8) * ((100vw - 320px) / (1550 - 320)));
//                 flex: 1;
//             }

//             .aauti_search_button_icon {
//                 padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1550 - 320)));
//             }

//         }

//         .aauti_login_button {
//             width: calc(120px + (142 - 120) * ((100vw - 320px) / (1550 - 320)));
//             height: calc(40px + (44 - 40) * ((100vw - 320px) / (1550 - 320)));
//             border-radius: calc(8px + (10 - 8) * ((100vw - 320px) / (1550 - 320)));
//             padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1550 - 320))) calc(20px + (30 - 20) * ((100vw - 320px) / (1550 - 320)));
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             cursor: pointer;
//             font-family: "Lato", sans-serif;
//             font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1550 - 320)));
//             font-weight: 700;
//             line-height: calc(18px + (20 - 18) * ((100vw - 320px) / (1550 - 320)));
//             text-align: center;
//             border-color: #3083EF;
//             color: #3083EF;
//             text-transform: capitalize;
//             border-width: calc(1px + (1.5 - 1) * ((100vw - 320px) / (1550 - 320)));

//         }

//         .aauti_signup_button {
//             background-color: #3083EF;
//             color: #ffffff;
//             width: calc(120px + (142 - 120) * ((100vw - 320px) / (1550 - 320)));
//             height: calc(40px + (44 - 40) * ((100vw - 320px) / (1550 - 320)));
//             border-radius: calc(8px + (10 - 8) * ((100vw - 320px) / (1550 - 320)));
//             text-transform: capitalize;
//             box-shadow: none;
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             justify-content: center;
//             padding-top: calc(7px + (9 - 7) * ((100vw - 320px) / (1550 - 320)));
//         }

//     }
// }
