.myCourses {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.drafs-con {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.hxLLRt {
  display: none !important;
}
.kgWrEL {
  align-items: flex-start !important;
  justify-content: flex-start !important;
  margin:0 0.5rem 0.5rem 0.5rem !important;
}
.hfqrxG{
  height: auto !important;
}
.gJAGSz.active {
  background: #007eff !important;
  color: #ffffff !important;
}
.eczmnE:not(.using-icon).active {
  background: #fff !important;
  border: 3px solid #007FFF !important;
}
.iRCxYP{
  margin:0.25rem 0 0 0 !important;
}
.myCourses-div8 {
  display: flex;
  flex-direction: row;
  gap: 2px;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.hi-plus {
  color: rgb(255, 255, 255);
  width: 60px;
  height: 60px;
  border: 0px;
  position: fixed;
  margin-right: 10px;
  top: 87vh;
  right: 18px;
  left: auto;
  border-radius: 50%;
  background-color: rgb(49, 102, 186);
  padding: 13px;
  cursor: pointer;
  margin-top: 10px;
}

.course-adhoc{
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.course-adhoc-cont{
  width: 100%;
  margin-bottom:10px;
}

.course-adhoc-flex{
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 4px;
}

.course-batches{
  border: 1px solid lightgray;
  padding: 5px 15px;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
}

.course-adhoc-slots{
  display: flex;
  flex-direction: row;
  gap:4px;
  align-items: center;
}

.course-adhoc-buttons{
  display: flex;
  flex-direction: row;
  gap: 10px; justify-content: flex-end; align-items: center;
}

.conflict-button{
  background-color: transparent;
  border-radius: 4px;
  border: none;
  color: #2076E5;
  align-self: flex-end;
  margin-top: 8px; cursor: pointer;
  // width: 100%;
}

.active-batch{
  border: 1px solid #2076E5;
}

.course-adhoc-cancel{
  padding: 10px 25px;
    border-radius: 4px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid lightgrey; background-color: transparent;

}

.course-adhoc-save {
  padding: 10px 25px;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #2076E5;border: none;
  color: #fff;

}

.erdVAe {
  width: 3% !important;
}

.hEpiWI{
  margin: 0.5rem !important;
}
