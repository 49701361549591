.main-div-web{
    width:100%;
    padding: 5px 10px;
}

.loader-div{
    // border: 1px solid red;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}