$main-chat-height: calc(100vh - 60px);

.chat-main-container {
  height: $main-chat-height;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  @media (min-width: 2000px) {
    background-color: #f2f2f6;
    padding: 16px 16px 0px 16px;
  }
}

.chat-wrapper {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #ffffff;
  @media (min-width: 2000px) {
    width: 76vw;
    height: calc($main-chat-height - 16px);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}

.empty-container {
  height: calc($main-chat-height);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .empty-container {
    height: calc(80vh - 100px);
  }
}

.search-box-and-chats-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-right: solid 1px #d1e0e7;
  column-gap: 10px;
  @media (min-width: 600px) {
    max-width: 350px;
  }

  @media (min-width: 992px) {
    max-width: 350px;
  }

  .chats-list-header {
    display: flex;
    flex-direction: column;

    .search-box-container {
      display: flex;
      flex-direction: row;
      padding: 16px;
      height: 72px;
      column-gap: 16px;

      @media (min-width: 600px) {
        padding-top: 0px;
        height: 56px;
      }

      .create-group-btn {
        height: 40px;
        cursor: pointer;
        border: none;
        background-color: #f2f2f6;
        padding: 8px;
        border-radius: 8px;
      }
    }
  }
  .overflow-container {
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .chats-list-container {
    height: calc($main-chat-height - 68px - 72px);
    width: 100%;
    margin: 0px;
    padding: 0px 16px 0px 16px;
    list-style-type: none;
    @media (min-width: 600px) {
      height: calc($main-chat-height - 110px);
    }
    @media (min-width: 2000px) {
      height: calc($main-chat-height - 126px);
    }
  }

  button.search-box-btn-style {
    border: none;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.chats-list-border {
  border-bottom: solid 0.6px whitesmoke !important;
  margin: 0 !important;
}
