.verify_user_layout {
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.verify-flex-items {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  margin: 10px 0px 8px 0px;
}

.margin_top_10 {
  margin: 15px 0px 8px 0px;
}
.gap_20 {
  gap: 20px;
}

.legal-inputs {
  width: calc(100% / 4);
}

.uploads_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  // align-items: center;
  margin-top: 10px;
}

.upload_sub_container {
  width: calc(100% / 2);
}

.custom_upload_verify {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.preview_upload_img {
  border: 1px solid lightgrey;
  border-radius: 6px;
  padding: 5px;
  position: relative;
  cursor: pointer;
  height: 120px;
  width: 130px;
  margin-top: 8px;
}

.remove_uploaded_img {
  padding: 5px !important;
  position: absolute !important;
  right: 1px;
  background-color: #ffffff !important;
  z-index: 110;
}

.remove_uploaded_img:hover {
  background-color: #ffffff !important;
}
.verify_main_section {
  flex: 1;
  overflow-y: auto;
}

@media (max-width: 980px) {
  .legal-inputs {
    width: calc(100% / 2.04);
  }
  .verify-flex-items {
    flex-wrap: wrap;
  }
  .uploads_container {
    flex-direction: column;
  }
  .upload_sub_container {
    width: 100%;
  }
  .gap_20 {
    flex-direction: row;
  }
}

@media (max-width: 600px) {
  .verify-flex-items {
    flex-direction: column;
  }
  .legal-inputs {
    width: 100%;
  }
  .uploads_container {
    flex-direction: column;
  }
  .gap_20 {
    flex-direction: row;
  }

  .upload_sub_container {
    width: 100%;
  }
}
