#FOOTER {
  // height: 315px;
  background: #1e1e1e;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
  height: max-content;
  width: 100%;
  padding-bottom: 20px;

  .footer_container {
    // display: flex;
    // flex-wrap: wrap;
    // padding-bottom: 50px;
    // border-bottom: solid 1.11px #FFFFFF80;
    // padding-left: 100px;
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 50px;
    // padding-left: 70px;
    border-bottom: solid 1.11px rgba(255, 255, 255, 0.5019607843);
    // padding-left: 100px;
    flex-direction: row;
    justify-content: space-between;

    .footer_about_follow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // padding-right: 70px;
    }

    .footer_logo_container {
      display: flex;
      flex-direction: column;

      .footer_logo_text {
        .footer_logo_text_label {
          width: 480px;
          // height: 72px;
          height: max-content;
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #d2d6ce;
          margin-top: 7px;
        }
      }
    }
    .footer_about_aauti {
      width: 200px;
      height: 112px;
      // margin-left: 100px;
      margin-right: 100px;

      .footer_about_aauti_ul {
        list-style-type: none;
      }

      .footer_about_aauti_label_head {
        font-family: "Poppins", sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #ffffff;
        margin-bottom: 10px;
      }

      .footer_about_aauti_label {
        font-family: "Poppins", sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #ffffffcc;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }

    .footer_follow_aauti {
      width: 260px;
      height: 112px;
      // margin-left: 100px;
      // margin-right: 100px;
      // border: solid #ffffff;

      .footer_follow_socials {
        // border: solid #ffffff;
        height: 30px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
        margin-bottom: 10px;

        .footer_follow_socials_label {
          font-family: "Poppins", sans-serif;
          font-size: 15px;
          font-weight: 700;
          line-height: 24px;
          text-align: left;
          color: #ffffff;
        }

        .footer_follow_socials_icons {
          display: flex;
          margin-left: 5px;
          margin-right: 5px;

          .footer_follow_socials_icon {
            margin: 3px;
          }

          .footer_contact_text {
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #ffffffcc;
          }
        }
      }
    }
  }

  .footer_copyright {
    // border: solid #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .footer_copyright_label {
      color: #ffffff;
      font-family: "Poppins", sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      // padding-left: 100px;
      padding-top: 25px;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  #FOOTER {
    height: max-content;
    background: #1e1e1e;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 65px;
    width: 100%;

    .footer_container {
      display: flex;
      padding-bottom: 50px;
      padding-left: 0px;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      .footer_about_follow {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
      }

      .footer_about_aauti {
        // width: 100%;
        height: max-content;
        // margin-left: 20px;
        // margin-right: 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .footer_about_aauti_ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 10px;
        margin-left: 90px;
      }
      .footer_logo_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      .footer_logo_text {
        .footer_logo_text_label {
          width: 100% !important;
          height: max-content !important;
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center !important;
          color: #d2d6ce;
          margin-top: 25px !important;
          margin-bottom: 7px;
        }
      }
    }

    .footer_copyright {
      .footer_copyright_label {
        color: #ffffff;
        font-family: "Poppins", sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        padding-left: 0;
        padding-top: 25px;
        padding-bottom: 25px;
        text-align: center !important;
        width: 100%;
      }
    }
  }
}
@media (max-width: 599px) {
  #FOOTER {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 50px;

    .footer_container {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      align-items: center;

      .footer_about_follow {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
      }

      .footer_logo_container {
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;

        .footer_logo_text {
          text-align: center;

          .footer_logo_text_label {
            width: 98%;
            text-align: center;
            margin-top: 20px;
          }
        }
      }

      .footer_about_aauti {
        width: 100%;
        text-align: center;
        height: fit-content;
        margin: 0px 0;
      }

      .footer_about_aauti_ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .footer_follow_aauti {
        text-align: center;
        margin: 20px 0;
      }

      .footer_follow_socials {
        justify-content: center;
        margin: 10px 0;
      }

      .footer_follow_socials_icons {
        justify-content: center;
      }
    }

    .footer_copyright {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
    }
  }
}
