$primary_color: #a2b9bc;
$secondary_color: #aaa;
$white_color: #ffffff;
$Aauti_theme_color: #3083ef;
$Aauti_black_color: #1e1e1e;
$balck_color: #000000;
$yello_tag_bG: #f7eea3;
$BORDER_COLOR: #f2f2f6;
$HEADING_COLOR: #1e1e1e;
$PARAGRAPH_COLOR: #626972;
$TAG_BACKGROUND: #f9ef99;
$GREY_TEXT: "#5C5B57";

// need to update

$darkBlack: #ffff;
$semiBlack: #ffff;
$lightBlack: #fff;

$logoDark: #fff;
$logoSemi: #fff;
$logoLight: #fff;

$darkGrey: #fff;
$semiGrey: #fff;
$lightGrey: #fff;

$iconDark: #fff;
$iconSemi: #fff;
$iconLight: #fff;

$colorText: #fff;

$primary_color: #a2b9bc;
$secondary_color: #aaa;
$white_color: #ffffff;
$balck_color: #000000;
$grey_text: #a8aaa8;
$Aauti_color: #3166ba;
$Regiter: #8687a7;
