.main-div{
    width:100%;
    padding: 5px 10px;
    height: 100%;
    margin: 0px;
}

.no-content-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.loader-div{
    // border: 1px solid red;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}