@import "../../../themes/colors.scss";

.cartCard,
.cartCard2 {
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5px;
  min-height: 190px;
  background-color: rgb(255, 255, 255);
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.cartCardAdd1 {
  width: auto;
  max-width: 70%;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cartCardAdd2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  max-width: 85%;
  justify-content: flex-start;
  margin-top: 5px;
  margin-left: 5px;
}

.content-con-card4 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.overview-container {
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  margin-top: 10px;
  padding: 20px;
}

.overview-container-package {
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  margin-top: 10px;
}
.overview-container-gig-pack {
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  margin-top: 10px;
}

.overview-container-package {
  border: none;
}

.overview-container-package2 {
  width: 35%;
  height: 85vh;
  overflow-y: scroll;
}

.gigPreview-text13 {
  cursor: pointer;
  color: $Aauti_theme_color;
  margin-left: 5px;
}

.right-content {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 580px;
  margin-top: 0px;
  height: 40vh;
  margin-left: 10px;
}

.detailsLeft-div7 {
  display: flex;
  flex-direction: row;
  background-color: #f9ef99;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 6px;
  gap: 10px;
  height: 20px;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.css-dfyq75-MuiPaper-root {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 00ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-sizing: content-box;
}

.addPackage-div1 {
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  margin-top: 10px;
}
.addpackage-skeleton-div1 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.addPackage-refund-div1 {
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.addPackage-percentIcon {
  width: 20px;
  height: 20px;
  color: #1e1e1e;
}
.addPackage-right-div1 {
  display: flex;
  align-items: center;
  width: 98%;
  justify-content: space-between;
}
.addPackage-div2 {
  width: 100%;
}

.addPackage-right-div2 {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.addPackage-div3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1000000;
  overflow-y: scroll;
  padding: 10px;
  justify-content: space-between;
  margin-top: 10px;
}

.addPackage-div4 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  z-index: 0;
  width: 45%;
  background-color: #fff;
}

.addPackage-div5 {
  padding: 8px;
  width: 100%;
}

.addPackage-div6 {
  width: 100%;
  border-radius: 6px;
  margin-top: -10px;
}

.addPackage-div8 {
  border-radius: 6px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.addPackage-div10 {
  width: 100%;
}

.addPackage-div11 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.addPackage-div12 {
  width: 54%;
  height: 67vh;
  border-radius: 10px;
  border: 1px solid rgba(176, 176, 176, 1);
}

.addPackage-div13 {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  margin: 5px;
}

.addPackage-div14 {
  margin-left: 0px;
}

.addPackage-div15 {
  font-size: 1.22rem;
  color: black;
}

.addPackage-div16 {
  width: 98%;
}

.addPackage-div17 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 0px;
  padding-top: 5px;
  width: 98%;
}

.addPackage-div18 {
  width: 45%;
  margin-bottom: 10px;
}

.addPackage-div19 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: fit-content;
  width: 100%;
  justify-content: flex-start;
  min-height: 20vh;
  overflow: scroll;
  padding: 10px;
}

.addPackage-div20 {
  width: 98%;
  align-self: flex-end;
  display: flex;
  padding: 5px;
  margin-bottom: 10px;
  justify-content: flex-end;
}

.addPackage-field1 {
  width: 100%;
}

.addPackage-text1 {
  font-size: 15px;
  cursor: pointer;
  color: #000000 !important;
}

.addPackage-text2 {
  color: red;
}

.addPackage-text6,
.addPackage-text7 {
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
  width: 100%;
}

.addPackage-text7 {
  color: grey;
  line-height: 20px;
  text-align: start;
}

.addPackage-button2 {
  max-width: 320px;
  height: auto;
  border-radius: 10px;
  padding: 5px;
  background-color: rgba(247, 247, 250, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addPackage-icon1 {
  color: #8687a7;
}

.addPackage-icon2 {
  background-color: lightGrey;
  border-radius: 50%;
  cursor: pointer;
}

.each-images-course {
  padding: 3px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.addPackage-inputBase1 {
  display: flex !important;
  padding-left: 1px;
}

.addPackage-inputButton1 {
  padding: 6px;
}

.addPackage-inputButton2 {
  position: absolute !important;
  right: 8px;
  top: 8px;
  color: grey;
}

.addPackage-paper1 {
  box-shadow: none !important;
  display: flex;
  background-color: #f2f2f6 !important;
  border-radius: 6px;
  height: 40px;
  min-width: 130px;
  max-width: 400px;
}

.addPackage-img1 {
  width: 42px;
  height: 42px;
  max-height: 42px;
  min-width: 42px;
  border-radius: 10px;
  margin-right: 0px;
}

.addPackage-img2 {
  width: 100%;
  height: 525px;
  position: relative;
  border-radius: 4px;
}

.addPackage-dialouge1 {
  width: 100%;
  height: 99%;
}

@media (max-width: 600px) {
  .addPackage-text1 {
    font-size: 14px;
  }

  .addPackage-div2 {
    width: 99%;
  }

  .addPackage-div3 {
    flex-direction: column;
  }

  .addPackage-div4 {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column-reverse;
    width: 99%;
  }

  .addPackage-div5 {
    padding: 5px;
    margin-bottom: 5px;
  }

  .addPackage-div10 {
    width: 98%;
  }

  .addPackage-div12 {
    width: 98%;
    margin-left: 0px;
    height: auto;
  }

  .addPackage-div19 {
    padding: 0;
    gap: 10px;
    margin-bottom: 8px;
  }

  .addPackage-button2 {
    width: 98%;
  }
}

.batches-div1 {
  flex-direction: row;
  gap: 8px;
  margin-top: 5px;
  align-items: center;
}

.batches-div3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card4-div1 {
  width: 100%;
  display: flex;
  height: 30px;
  flex-direction: row;
  margin-right: 10px;
  align-items: center;
  cursor: pointer;
}

.card4-div2 {
  width: 25%;
  cursor: pointer;
}

.card4-div3 {
  width: 100%;
  margin-top: 5px;
}

.card4-div4 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-bottom: 5px;
}

.card4-div5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 5px;
}

.card4-button1 {
  border: none;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  margin-right: 10px;
  align-items: center;
}

.card4-icon1 {
  color: #000000;
}

.card4-img1 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-right: 8px;
}

.card4-text1 {
  color: black;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.card4-text2 {
  color: #1e98d7;
}

.gigPackageCard-div1 {
  width: 33%;
  cursor: pointer;
}

.gigPackageCard-div2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: 5px;
  justify-content: space-between;
  min-height: 43px;
}

.gigPackageCard-div3,
.gigPackageCard-div4 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.gigPackageCard-div4 {
  gap: 5px;
  margin-top: 8px;
}

.gigPackageCard-div5 {
  display: flex;
  gap: 5px;
  overflow: scroll;
  width: 100%;
}

.gigPackageCard-div6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.gigPackageCard-img1 {
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  min-width: 100px;
  border-radius: 10px;
  margin-right: 8px;
}

.gigPackageCard-text1 {
  color: black;
  line-height: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.gigPackageCard-text2 {
  color: #1e98d7;
}

.gigPackageCard-text3 {
  font-size: 7px;
  width: 35px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-align: center;
}

.gigPackageCard-avatar1 {
  height: 23px !important;
  width: 23px !important;
}

.mainCompPack-div1 {
  cursor: pointer;
  padding-bottom: 5px;
}

.mainComp-div2 {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  overflow-x: scroll;
  gap: 10px;
}

//More Packages
.morePackages-div1 {
  width: 100%;
  padding: 1%;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.main-cont-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  height: 100%;
}

.morePackages-div2 {
  height: fit-content;
  background: #fff;
  border-radius: 5px;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 280px;
  max-width: 280px;
  min-width: 280px;
}

.morePackages-div3 {
  overflow: hidden;
  overflow-y: scroll;
  height: 85vh;
  width: calc(100% - 280px);
}

@media (max-width: 600px) {
  .morePackages-div1 {
    flex-direction: column;
  }

  .morePackages-div2 {
    width: 100%;
    background-color: transparent;
    padding: 0px;
    max-width: 100%;
    min-width: 100%;
    box-shadow: none;
  }

  .morePackages-div3 {
    width: 100%;
    height: 78vh;
  }
}

//My Packages

.myPackages-div1 {
  width: 99%;
  padding: 1%;
}

.myPackages-div2 {
  border: 1px solid #e0e0e0;
  min-height: 80vh;
  border-radius: 10px;
}

.myPackages-div3 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.myPackages-div4 {
  width: 60%;
  margin-right: 10px;
}

.myPackages-div5 {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
}

.myPackages-div6 {
  background-color: #fff;
  width: 100%;
  overflow: scroll;
  height: 70vh;
}
.myPackages-div9 {
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  display: flex;
  padding: 5px;
  gap: 10px;
  width: 100%;
}
.myPackages-div7 {
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}

.myPackages-div8 {
  display: flex;
  flex-direction: row;
  gap: 2px;
  margin-bottom: 8px;
}

.categories-map-gig {
  width: 100%;
  display: flex;
}

.subcategory-count-gig {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
}

.overview-container-gig-pack {
  border-radius: 7px;
  width: 100%;
  border: none;
  margin-top: 15px;
}

.title-view-all-cont-gig-pack {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.detailsComp-div1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  overflow: hidden;
  overflow-x: scroll;
  margin-top: 10px;
}

//Package Card2

.PackageCard2-div1 {
  position: relative;
  cursor: pointer;
}

.PackageCard2-div3 {
  background: linear-gradient(
    180deg,
    rgba(30, 30, 30, 0.24) 66.11%,
    rgba(0, 0, 0, 0.8) 99.44%
  );
  position: absolute;
  border-radius: 10px;
  bottom: 0px;
  height: 180px;
  width: 100%;
}

.PackageCard2-div6 {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  margin-top: 10px;
  justify-content: space-between;
}

.PackageCard2-div7 {
  display: flex;
  align-items: flex-end;
  gap: 6px;
}

.PackageCard2-text1 {
  width: 98%;
  line-height: 22px;
  overflow-wrap: anywhere;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.PackageCard2-text2 {
  line-height: 22px;
  font-size: 12px !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.PackageCard2-text3 {
  line-height: 22px;
  margin-right: 5px;
  font-size: 12px !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  color: #5c5b57;
}

.PackageCard2-text3:hover {
  color: $Aauti_theme_color;
}

.PackageCard2-text4 {
  width: fit-content;
  height: 25px;
  padding: 4px 8px;
  gap: 12px;
  font-size: 12px !important;
  line-height: 18px;
  border-radius: 6.67px;
  background-color: rgba(249, 239, 153, 1);
  box-sizing: border-box;
}

@media (min-width: 1583px) {
  .PackageCard2-text2,
  .PackageCard2-text2,
  .PackageCard2-text3,
  .PackageCard2-text4 {
    font-size: 12px !important;
  }

  .PackageCard2-text1 {
    font-size: 14px !important;
  }
}

.drafs-con {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}
.no-search-results {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-height: 64vh;
}

//Package Details
.packageDetails-div1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.packageDetails-div3 {
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 86vh;
  padding-right: 3%;
  overflow-y: scroll;
  align-items: flex-start;
}

.packageDetails-div4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  width: 92%;
  border-radius: 6px;
  height: auto;
}

.packageDetails-div5 {
  display: flex;
  flex-direction: row;
  width: 63%;
}

.packageDetails-div7 {
  width: 99%;
  margin-bottom: 0px;
  margin-top: 10px;
}

.packageDetails-div9 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: scroll;
}

.packageDetails-img1 {
  width: 100%;
  border-radius: 5px;
  height: 457px;
}

.packageDetails-text1 {
  margin-left: 10px;
}

.packageDetails-text3 {
  color: rgb(0, 0, 0);
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.packageDetails-text5 {
  color: #626972;
  width: 100%;
  overflow-wrap: anywhere;
  margin-bottom: 10px;
}

.packageDetails-text6 {
  color: #1e1e1e;
}

.packageDetails-button1 {
  width: 40% !important;
  margin-top: 10px !important;
  align-self: center !important;
}

@media (max-width: 599px) {
  .packageDetails-div4 {
    width: 100%;
    height: auto;
    flex-direction: column;
  }

  .myPackages-div4 {
    min-width: 82%;
  }
  .packageDetails-img1 {
    width: 100%;
    height: 300px;
  }

  .right-content {
    width: 98%;
    margin-left: 2px;
  }

  .head-top-card2s {
    padding: 5px;
  }

  .packageDetails-text3 {
    width: 99%;
  }

  .overview-container,
  .overview-container-package,
  .overview-container-package2 {
    width: 99%;
    height: auto;
    padding: 5px;
  }

  .packageDetails-div7 {
    width: 99%;
  }

  .packageDetails-div3 {
    width: 99%;
    padding-right: 0%;
    height: auto;
  }
  .title-view-all-cont-gig-pack {
    align-items: flex-start;
  }
}

@media only screen and (min-width: 610px) and (max-width: 1023px) {
  .packageDetails-div4 {
    width: 95%;
    height: auto !important;
    flex-direction: column;
  }

  .packageDetails-div9 {
    height: auto;
  }

  .right-content {
    width: 98%;
    margin-left: 2px;
  }

  .packageDetails-text3 {
    width: 94%;
  }

  .overview-container,
  .overview-container-package,
  .overview-container-package2 {
    width: 99%;
    height: auto;
    padding: 5px;
  }

  .packageDetails-div7 {
    width: 60%;
  }

  .packageDetails-div3 {
    width: 99%;
    padding-right: 0%;
    height: auto;
  }
}

//PackageDeialsLoader
.packageLoader-div2 {
  margin-bottom: 10px !important;
}

.addPackage-field1::-webkit-inner-spin-button,
.addPackage-field1::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.addPackage-adhoc-div1 {
  width: 100%;
  padding: 8px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addPackage-rating-div1,
.addPackage-rating-div4 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.addPacakage-rating-icon1 {
  height: 16px;
  width: 16px;
  z-index: 100;
}
.addPackage-rating-div4 {
  gap: 2px;
}

.addPackage-rating-div2 {
  display: flex;
  flex-direction: row;
  margin-left: 2px;
  align-items: center;
  justify-content: space-between;
  width: 99%;
}

.addPackage-rating-div3 {
  display: flex;
  flex-direction: row;
  width: 90%;
  gap: 5px;
}
