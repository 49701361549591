/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App {
  position: relative;
}

::-webkit-scrollbar {
  width: 4px;
  display: none !important;
}

::-webkit-scrollbar-thumb {
  /* // background-color: #888; */
  background-color: lightblue;
  border-radius: 3px;
}

/* For Firefox */
::-moz-scrollbar {
  width: 4px;
}

::-moz-scrollbar-thumb {
  /* // background-color: #888; */
  background-color: lightblue;
  border-radius: 3px;
}

/* hide scrollbar in firefox */
* {
  scrollbar-width: none;
}

.menustyles {
  border-width: 0px;
  background-color: transparent;
  outline: none;
  padding: 0px;
  position: fixed;
  /* bottom: -8px; */
  left: 70px;
  top: 89px;
  margin-left: -20px;
  cursor: pointer;
  z-index: 600000;
}

.right-buttonnn {
  color: #066cb5;
}

.AiFillLeftCirclestyle {
  display: flex;
  position: fixed;
  top: 70px;
  left: 160px;
  cursor: pointer;
  color: #066cb5;
}

.AiFillLeftCirclestyle:hover {
  color: #066cb5;
}

.closebtn {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #f1f1f1;
  transition: 0.5s;
  margin-bottom: 10px;
  position: relative;

  z-index: 9000;
}

.rdrMonthAndYearPickers {
  display: none !important;
}

.rdrDateDisplayWrapper {
  background-color: none !important;
}
.rdrCalendarWrapper {
  width: 100% !important;
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 24px !important;
  padding-top: 10px;
}
.fBdFME {
  height: 65vh;
}
