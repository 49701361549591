@import "../../themes/colors.scss";

.DABMain {
  margin-bottom: 20px;
  width: 100%;
}

.categCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95%;
  width: 95%;
}

.search-con {
  display: flex;
  width: 97%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.CatEach {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 7px;
  margin: 7px;
  cursor: pointer;
  transition: transform 0.3s;
}

.CateahImage {
  height: 90px;
  width: 110px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.catEachName {
  margin: 5px;
  font-weight: 500;
  color: grey;
}

.CatEach:hover {
  transform: scale(1.1);
  border: 1px solid $Aauti_theme_color;
}

.ShowAllCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}

.showAllIconCont {
  background-color: $Aauti_theme_color;
  padding: 8px 10px 0px 10px;
  border-radius: 5px;
}

.scrolContCat {
  width: 100%;
  position: fixed;
  top: 55px;
  top: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  left: 0;
  z-index: 110;
  // border-bottom: 1px solid lightgrey;
  background-color: #ffffff;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 0.5px 2px;
  flex-wrap: wrap;
}

.scrollEachCat {
  // border: 1px solid lightgray;
  padding: 10px 15px 10px 15px;
  margin: 5px;

  border-radius: 5px;
  color: black;
  transform: transform 0.3s;
  cursor: pointer;
  width: fit-content;
}

.scrollEachCat:hover {
  transform: scale(1.1);
}

.BlockMain {
  display: flex;
  justify-content: center;
  width: 100%;
}

.arrowIcon {
  font-size: 23px;
  color: $Aauti_theme_color;
  font-weight: 700;
  margin-top: 1px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
}

.viewMore {
  display: flex;
  flex-direction: row;
  transform: transform 0.3s;
}

.viewMore:hover {
  transform: scale(1.05);
  border-bottom: 1px solid $Aauti_theme_color;
}

.home-div1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.home-div2 {
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-div3 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-bottom: 4px;
}

.home-button1 {
  border: none;
  background-color: #fff;
}

.home-text1 {
  cursor: pointer;
  margin-top: 4px;
  font-size: 16px;
  color: $Aauti_theme_color;
}

.day-events-cards {
  display: flex;
  // width: 100%;
  gap: 5px;
  flex-direction: column;
}

.today-event-card {
  width: 100%;
  // height: 100px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

button.course_button {
  width: 85px;
  padding: 5px;
  font-size: 17px;
  color: #fff;
  border: solid 2px #ffffff;
  border-radius: 7px;
  background: $Aauti_theme_color;
  font-family: "Mulish", sans-serif !important;
  font-weight: 600;
}

.time-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.upcoming-classes-container {
  // width: 350px;

  // cursor: pointer;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #ffffff;
  width: 460px;
  z-index: 100;
  gap: 4px;
  display: flex;
  // padding: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: height 0.2s ease;
  overflow: scroll;

  // animation: myMove 2s infinite ease-in-out;
}
// .upcoming-classes-container:hover {
//   height: 300px;
// }

.bot-session-cont {
  position: fixed;
  bottom: 25px;
  right: 5px;
  z-index: 200;
}

@media screen and (max-width: 468px) {
  .bot-session-cont {
    bottom: 60px;
  }
}



.content-upcoming-class {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  height: auto;
  border: 1px solid $Aauti_theme_color;
  box-shadow: 0px 0px 4px 0px #3083ef66;
  background-color: transparent;
  border-radius: 10px;
  background-color: #ffffff;
}

// .myMove.blink {
//   animation: blink-animation 1s steps(5, start) infinite;
//   -webkit-animation: blink-animation 1s steps(5, start) infinite;
// }

.bot-styles {
  -webkit-box-shadow: 0 0.5px 13px 0.5px #948e8e;
  -moz-box-shadow: 0 0.5px 13px 0.5px #948e8e;
  box-shadow: 0 0.5px 13px 0.5px #948e8e;
}

@keyframes myMove {
  0% {
    width: 462px;
    height: 78px;
    border-radius: 5px;
    // box-shadow: rgba(30, 102, 225, 0.35) 15px 15px 15px 15px;
    // transform: rotate(0deg);
  }

  25% {
    width: 464px;
    height: 79px;
    // background-color: #2ecc71;
    border-radius: 6px;
    // box-shadow: rgba(12, 96, 240, 0.35) 15px 15px 15px 15px;
    // transform: rotate(5deg);
  }

  50% {
    width: 466px;
    height: 80px;
    // background-color: #9b59b6;
    border-radius: 7px;
    // box-shadow: rgba(6, 88, 229, 0.89) 15px 15px 15px 15px;
    // transform: rotate(0deg);
  }

  75% {
    width: 464px;
    height: 79px;
    // background-color: #e74c3c;
    border-radius: 6px;
    // box-shadow: rgba(12, 96, 240, 0.35) 15px 15px 15px 15px;
    // transform: rotate(-5deg);
    //9849212950 sri anjaneya enterprices 11000
  }

  100% {
    width: 462px;
    height: 78px;
    // background-color: #3498db;
    border-radius: 5px;
    // transform: rotate(0deg);
    // box-shadow: rgba(30, 102, 225, 0.35) 15px 15px 15px 15px;
  }
}

.next-class-join-btn-session {
  border: 2px solid #3083ef;
  background: #3083ef;
  color: #ffffff;
  width: 80px;
  height: 42px;
  cursor: pointer;
  border-radius: 8px;
  opacity: 0px;
  font-weight: 700;
  font-size: 18px;
  margin-right: 15px;
}

.detailsLeft-divchatbot {
  // position: fixed;
  // bottom: 100px;
  // right: 30px;
  width: auto;
  z-index: 100;
}
