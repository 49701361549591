@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  width: 100vw;
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: url("../fonts/HelveticaNeueBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueLight";
  src: url("../fonts/HelveticaNeueLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@mixin label-style {
  height: 18px;
  width: 140px;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}

#LEARN_NEW_PROFESSION_TEACH {
  // height: calc(300px + (545 - 300) * ((100vw - 320px) / (1550 - 320)));
  display: flex;
  margin-top: calc(40px + (80 - 40) * ((100vw - 320px) / (1550 - 320)));
  position: relative;
  width: 100%;

  height: max-content;
  margin-bottom: 40px;
  flex-direction: row;
  align-items: center;

  .learn_new_profession_frame {
    margin-left: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));
    // margin-right: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));
    width: calc(300px + (650 - 300) * ((100vw - 320px) / (1550 - 320)));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    .learn_new_profession_text {
      width: calc(300px + (724 - 300) * ((100vw - 320px) / (1550 - 320)));
      // height: calc(150px + (270 - 150) * ((100vw - 320px) / (1550 - 320)));
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;

      height: max-content;

      .learn_new_profession_label {
        width: calc(300px + (724 - 300) * ((100vw - 320px) / (1550 - 320)));
        // height: calc(100px + (215 - 100) * ((100vw - 320px) / (1550 - 320)));
        font-size: calc(30px + (54 - 30) * ((100vw - 320px) / (1550 - 320)));
        font-weight: 700;
        font-family: "HelveticaNeueBold", sans-serif;
        line-height: calc(40px + (66 - 40) * ((100vw - 320px) / (1550 - 320)));
        text-align: left;
        height: max-content;

        .learn_new_profession_span {
          color: #3083ef;
        }
      }

      .learn_new_profession_label2 {
        width: calc(250px + (724 - 300) * ((100vw - 320px) / (1550 - 320)));
        // height: calc(20px + (28 - 20) * ((100vw - 320px) / (1550 - 320)));
        height: max-content;
        font-family: "HelveticaNeueLight", sans-serif;
        font-size: calc(16px + (23 - 16) * ((100vw - 320px) / (1550 - 320)));
        font-weight: 600;
        // line-height: calc(12px + (30 - 12) * ((100vw - 320px) / (1550 - 320)));
        text-align: left;
        color: #5c5b57;
        margin-top: calc(8px + (13 - 8) * ((100vw - 320px) / (1550 - 320)));
        // line-height: 28px;
      }

      .text_underline_container {
        position: absolute;
        top: calc(40px + (70 - 40) * ((100vw - 320px) / (1550 - 320)));
        left: calc(20px + (40 - 20) * ((100vw - 320px) / (1550 - 320)));
      }
    }

    .search_all_courses_container {
      width: calc(300px + (625 - 300) * ((100vw - 320px) / (1550 - 320)));
      height: calc(40px + (60 - 40) * ((100vw - 320px) / (1550 - 320)));
      padding: calc(10px + (18 - 10) * ((100vw - 320px) / (1550 - 320)))
        calc(10px + (19 - 10) * ((100vw - 320px) / (1550 - 320)))
        calc(10px + (18 - 10) * ((100vw - 320px) / (1550 - 320)))
        calc(8px + (14 - 8) * ((100vw - 320px) / (1550 - 320)));
      gap: 0px;
      border-radius: 10px;
      border: solid 2px #e4e4e4;
      margin-top: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
      display: flex;
      align-items: center;

      .search_all_courses_input {
        flex: 1;
      }
    }

    .learn_new_profession_button_container {
      margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1550 - 320)));
      width: calc(300px + (516 - 300) * ((100vw - 320px) / (1550 - 320)));
      height: calc(40px + (60 - 40) * ((100vw - 320px) / (1550 - 320)));
      gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1550 - 320)));
      display: flex;

      .learn_new_profession_button {
        width: calc(150px + (240 - 150) * ((100vw - 320px) / (1550 - 320)));
        height: calc(40px + (55 - 40) * ((100vw - 320px) / (1550 - 320)));
        padding: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)))
          calc(15px + (30 - 15) * ((100vw - 320px) / (1550 - 320)));
        gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
        border-radius: 10px;
        background: #3083ef;
        text-transform: capitalize;
        font-family: "Lato", sans-serif;
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1550 - 320)));
        font-weight: 600;
        line-height: calc(16px + (20 - 16) * ((100vw - 320px) / (1550 - 320)));
        text-align: center;
      }

      .see_how_it_works_container {
        position: relative;
        display: flex;
        align-items: center;

        .see_how_it_works_label {
          font-family: "Lato", sans-serif;
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1550 - 320)));
          font-weight: 600;
          line-height: calc(
            16px + (20 - 16) * ((100vw - 320px) / (1550 - 320))
          );
          text-align: center;
          margin-left: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
          // width: calc(100px + (136 - 100) * ((100vw - 320px) / (1550 - 320)));
          height: calc(16px + (20 - 16) * ((100vw - 320px) / (1550 - 320)));
        }

        .text_underline {
          position: absolute;
          // top: calc(30px + (45 - 30) * ((100vw - 320px) / (1550 - 320)));
          right: calc(5px + (10 - 5) * ((100vw - 320px) / (1550 - 320)));
          bottom: -27px;
        }
      }
    }
  }

  .learn_professions_right_image {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: calc(20px + (100 - 20) * ((100vw - 320px) / (1550 - 320)));

    img {
      height: calc(300px + (515 - 300) * ((100vw - 320px) / (1550 - 320)));
      margin-top: calc(20px + (45 - 20) * ((100vw - 320px) / (1550 - 320)));
    }
  }

  .learn_professions_right_image_top {
    display: none;
  }
}

// #WHAT_AAUTI_HELPS_YOU_IN {
//     height: 836px;
//     background-color: #F2F2F6;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-between;

//     .what_aauti_helps_you_in_container {
//         .what_aauti_helps_you_in_text {
//             width: 736px;
//             height: 126px;
//             // margin-top: 68px;
//             // border: solid;
//             margin-right: 80px;

//             .what_aauti_helps_you_in_label {
//                 font-family: "HelveticaNeueBold", sans-serif;
//                 font-size: 44px;
//                 font-weight: 700;
//                 line-height: 70px;
//                 text-align: left;

//                 .what_aauti_helps_you_in_span {
//                     color: #3083EF;
//                 }

//                 .what_aauti_helps_you_in_label2 {
//                     font-family: "HelveticaNeueLight", sans-serif;
//                     font-size: 22px;
//                     font-weight: 400;
//                     line-height: 36px;
//                     text-align: left;

//                 }
//             }
//         }
//     }
// }

#WHAT_AAUTI_HELPS_YOU_IN {
  height: calc(700px + (836 - 700) * ((100vw - 320px) / (1550 - 320)));
  background-color: #f2f2f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .what_aauti_helps_you_in_image_five {
    width: 45%;
    margin-right: 5%;
    height: 80%;
  }

  .what_aauti_helps_you_in_container {
    .what_aauti_helps_you_in_text {
      width: calc(600px + (736 - 600) * ((100vw - 320px) / (1550 - 320)));
      height: calc(100px + (126 - 100) * ((100vw - 320px) / (1550 - 320)));
      margin-right: calc(60px + (80 - 60) * ((100vw - 320px) / (1550 - 320)));

      .what_aauti_helps_you_in_label {
        font-family: "HelveticaNeueBold", sans-serif;
        font-size: calc(30px + (44 - 30) * ((100vw - 320px) / (1550 - 320)));
        font-weight: 700;
        line-height: calc(50px + (70 - 50) * ((100vw - 320px) / (1550 - 320)));
        text-align: left;

        .what_aauti_helps_you_in_span {
          color: #3083ef;
        }

        .what_aauti_helps_you_in_label2 {
          font-family: "HelveticaNeueLight", sans-serif;
          font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1550 - 320)));
          font-weight: 400;
          line-height: calc(
            28px + (36 - 28) * ((100vw - 320px) / (1550 - 320))
          );
          text-align: left;
        }
      }
    }

    .what_aauti_helps_you_in_image {
      width: 63%;
    }
  }
}

@media screen and (max-width: 899px) {
  #LEARN_NEW_PROFESSION_TEACH {
    flex-direction: column;
    align-items: center;
    height: max-content;
    padding-bottom: 40px;
    margin-top: 80px;

    .learn_new_profession_frame {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .learn_new_profession_text {
        height: max-content;

        .learn_new_profession_label {
          height: max-content;
        }

        .learn_new_profession_label2 {
          width: 100%;
          height: max-content;
          margin-bottom: 10px;
        }
      }
    }

    .learn_professions_right_image {
      display: none;
    }

    .learn_professions_right_image_top {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  #WHAT_AAUTI_HELPS_YOU_IN {
    width: 100%;
    height: max-content !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;

    .what_aauti_helps_you_in_image_five {
      display: none;
    }

    .what_aauti_helps_you_in_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;

      .what_aauti_helps_you_in_text {
        text-align: center !important;
        margin: 0px;
        width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        height: max-content !important;
        margin-right: 0px !important;

        .what_aauti_helps_you_in_label {
          text-align: center !important;
        }

        .what_aauti_helps_you_in_label2 {
          margin-bottom: 14px;
        }
      }

      .what_aauti_helps_you_in_image {
        width: 100% !important;
        padding: 20px;
      }
    }
  }
}

.what_aauti_helps_you_in_image_mobile {
  display: none;
}

@media screen and (max-width: 500px) {
  #LEARN_NEW_PROFESSION_TEACH
    .learn_new_profession_frame
    .learn_new_profession_text
    .learn_new_profession_label {
    font-size: 27px;
  }

  #LEARN_NEW_PROFESSION_TEACH
    .learn_new_profession_frame
    .learn_new_profession_text
    .text_underline_container {
    position: absolute;
    top: 35px;
    left: 0;
  }

  #WHAT_AAUTI_HELPS_YOU_IN
    .what_aauti_helps_you_in_container
    .what_aauti_helps_you_in_image {
    display: none;
  }

  #WHAT_AAUTI_HELPS_YOU_IN
    .what_aauti_helps_you_in_container
    .what_aauti_helps_you_in_image_mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    width: 100%;
    padding: 15px;
  }

  #WHAT_AAUTI_HELPS_YOU_IN
    .what_aauti_helps_you_in_container
    .what_aauti_helps_you_in_text
    .what_aauti_helps_you_in_label {
    text-align: center !important;
    font-size: 30px !important;
  }
}
